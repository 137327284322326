/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

// import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg';
// import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';

import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;
const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded-sm sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition-all duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded-sm sm:rounded-l-none px-8 py-3`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
// const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
// const LogoImg = tw.img`w-8`;
// const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition-all duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>HQ Address </ColumnHeading>
            <LinkList>
              <LinkListItem>
                1745 Banks rd <br /> Margate, FL 33063
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Quality Assurance</ColumnHeading>
            <LinkList>
              <LinkListItem>
                - Specimen Types <br />
                &nbsp;&nbsp; Collected Under IRB <br />
                &nbsp;&nbsp; Approved Collection Protocols
              </LinkListItem>

              <LinkListItem>
                - Quality Management System <br />
                &nbsp;&nbsp; 21 CFR 820 & ISO 13485 Compliant
              </LinkListItem>
              <LinkListItem>
                - Freezer Inventory Software
                <br />
                &nbsp;&nbsp;21 CFR 11 Compliant and <br />
                &nbsp;&nbsp;24/7 Temperature Monitoring System
              </LinkListItem>
              <LinkListItem>
                - Unique Collections/Projects
                <br />
                &nbsp;&nbsp;Managed to Specifications
              </LinkListItem>
            </LinkList>
          </Column>

          {/* <Column>
            <ColumnHeading>Press</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Logos</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Events</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Stories</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Office</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}

          {/* <Column>
            <ColumnHeading>Team</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Career</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Founders</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Culture</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Onboarding</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}

          {/* <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/privacypolicy">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/termsofservice">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}

          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>We deliver high quality blog posts written by professionals weekly. And we promise no spam.</SubscribeText>
              <SubscribeForm method="get" action="#">
                <Input type="email" placeholder="Your Email Address" />
                <SubscribeButton type="submit">Subscribe</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer id="footerlogoarea">
            <img id="footercailogo" src="/newlogowhite.png" alt="logo" />
          </LogoContainer>
          <CopywrightNotice>&copy; 2021 Complex Antibodies Inc. All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            {/* <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink> */}
            <SocialLink href="https://twitter.com/cmplxantibodies">
              <TwitterIcon />
            </SocialLink>
            {/* <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
