import React from 'react';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Navbar, Nav, Container } from 'react-bootstrap';

export default function Navbar2() {
  return (
    <>
      <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            <img src="/newlogowhite.png" max-width="100%" width="250" height="auto" className="d-inline-block align-top" alt="React Bootstrap logo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto d-flex justify-content-center">
              <Nav.Link href="#inventory">Inventory</Nav.Link>
              <Nav.Link href="#howToOrder">How-to-Order</Nav.Link>
              <Nav.Link href="#Services">Services</Nav.Link>
              <Nav.Link href="#aboutbiz">About Us</Nav.Link>
              <Nav.Link href="#facility">Facility</Nav.Link>
              <Nav.Link href="#faq">FAQ</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
