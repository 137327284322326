import React from 'react';
import tw from 'twin.macro'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Inventory from 'components/inventory/inventory';
import Hero from 'components/hero/BackgroundAsImage.js';
import Services from 'components/features/DashedBorderSixFeatures';
import AboutBiz from 'components/features/TwoColSingleFeatureWithStats2.js';
import AboutUs from 'components/features/TwoColWithTwoFeaturesAndButtons.js';
import Facility from 'components/testimonials/TwoColumnWithImageAndProfilePictureReview.js';
import FAQ from 'components/faqs/SimpleWithSideImage.js';
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from 'components/footers/FiveColumnWithBackground.js';
import customerSupportIllustrationSrc from 'images/customer-support-illustration.svg';
import GetStarted from 'components/cta/GetStarted';
import HowToOrder from 'components/features/TwoColWithSteps.js';
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png';

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <AnimationRevealPage>
    <Hero />
    <AboutBiz />
    <Inventory />
    <HowToOrder
      subheading={<Subheading>STEPS</Subheading>}
      heading={
        <>
          How to order our <HighlightedText>Specimens</HighlightedText>
        </>
      }
      textOnLeft={false}
      imageSrc={macHeroScreenshotImageSrc}
      imageDecoratorBlob={true}
      decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
    />
    <GetStarted />
    <Services />
    <AboutUs />

    <Facility
      subheading="Our Facility"
      heading={<>{<span tw="text-primary-500">Inventory</span>}</>}
      description="Complex Antibodies, Inc. meets the regulatory requirements for your specimen collections.  Our extensive selection of diagnostic remnant specimens is collected under IRB approved collection protocols.  Protection of human subjects is our utmost concern. These samples are de-identified and are not traceable back to the subject.   Individual identifiable health information is not provided.  Information provided with the specimen includes a unique Identification number, age, gender, collection date, matrix, volume, test value if applicable, and viral testing if available."
      testimonials={[
        {
          imageSrc: '/Building.jpg',
          profileImageSrc:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
          quote:
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.',
          customerName: 'Complex Antibodies',
          customerTitle: '1745 Banks rd Margate, FL 33063',
        },
        {
          imageSrc: '/processing.jpg',
          profileImageSrc: '/complexlogo.png',
          quote:
            'Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.',
          customerName: 'Processing Area',
          customerTitle: 'IRB Protocol Approved',
        },
        {
          imageSrc: '/freezers.jpg',
          profileImageSrc: '/complexlogo.png',
          quote:
            'Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.',
          customerName: 'Freezers',
          customerTitle: '24/7 Temperature Monitored',
        },
        {
          imageSrc: 'freezer.jpg',
          profileImageSrc: '/complexlogo.png',
          quote:
            'Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.',
          customerName: 'Specimens',
          customerTitle: '24/7 Temperature Monitored',
        },
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    <Footer />
  </AnimationRevealPage>
);
