import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react scripts/dist/react-bootstrap-table.min.css';
// import 'react-bootstrap-table-toolkit/dist/react-bootstrap-table-toolkit.min.css';
// import 'react-bootstrap-table-paginator/dist/react-bootstrap-table-paginator.min.css';
import TagManager from 'react-gtm-module';

Modal.setAppElement('#root');

const tagManagerArgs = {
  gtmId: 'GTM-5W3KDX4',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
