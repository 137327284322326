// import React, { Component } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import { CSVLink } from 'react-csv';

// const selectRow = {
//   mode: 'checkbox',
//   clickToSelect: true,
// };

// export default class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       columns: [
//         {
//           dataField: 'Sample_ID',
//           text: 'Sample ID',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '155px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'AgeFld',
//           text: 'Age',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '50px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'Gender',
//           text: 'Gender',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '85px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'ProductName',
//           text: 'Product Name',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '160px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'ValueFld',
//           text: 'Value',
//           sort: true,
//           sortFunc: (a, b, order, dataField, rowA, rowB) => {
//             if (order === 'asc') {
//               return Number(b) - Number(a);
//             }
//             return Number(a) - Number(b);
//           },
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '75px', textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
//           },
//         },
//         {
//           dataField: 'Unit_of_Measure',
//           text: 'Units',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '65px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'SampleType',
//           text: 'Sample Type',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '90px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'Collection_Date',
//           text: 'Collection Date',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '115px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'TotalCurrentAmount',
//           text: 'Volume (mLs)',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '75px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'Thaw_Freeze',
//           text: '# of Thaw-Freeze',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '95px', textAlign: 'center' };
//           },
//         },
//         {
//           dataField: 'Instrument',
//           text: 'Instrument',
//           sort: true,
//           align: 'center',
//           headerStyle: (column, colIndex) => {
//             return { width: '180px', textAlign: 'center' };
//           },
//         },
//       ],
//     };
//   }

//   render() {
//     const customTotal = (from, to, size) => (
//       <span className="react-bootstrap-table-pagination-total">
//         &nbsp; Showing {from} to {to} of {size} Results
//       </span>
//     );

//     const options = {
//       paginationSize: 6,
//       pageStartIndex: 0,
//       firstPageText: 'First',
//       prePageText: 'Back',
//       nextPageText: 'Next',
//       lastPageText: 'Last',
//       showTotal: true,
//       paginationTotalRenderer: customTotal,
//       disablePageTitle: true,
//       sizePerPageList: [
//         { text: '5', value: 5 },
//         { text: '10', value: 10 },
//         { text: '30', value: 30 },
//         { text: '50', value: 50 },
//         { text: '100', value: 100 },
//         { text: 'All', value: this.props.state.products.length },
//       ],
//     };

//     return (
//       <div>
//         {this.props.state.showTable && (
//           <div>
//             {/* CSV Export Button */}
//             <CSVLink
//               data={this.props.state.products}
//               filename="ComplexAntibodies-ProductSelection.csv"
//               style={{
//                 color: 'white',
//                 borderStyle: 'solid',
//                 backgroundColor: 'rgb(58, 12, 207)',
//                 padding: '12px 20px',
//                 marginBottom: '15px',
//                 boxSizing: 'border-box',
//                 border: '2px solid rgb(58, 12, 207)',
//                 borderRadius: '20px',
//               }}
//             >
//               Export Selected
//             </CSVLink>
//             <br />
//             <text>
//               *Indicates some degree of hemolysis <br />
//               **Indicates some degree of icteremia
//             </text>

//             {/* Bootstrap Table */}
//             <BootstrapTable
//               keyField="_id"
//               data={this.props.state.products}
//               columns={this.state.columns}
//               pagination={paginationFactory(options)}
//               selectRow={selectRow}
//             />
//           </div>
//         )}
//       </div>
//     );
//   }
// }

// import React, { Component } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import { CSVLink } from 'react-csv';

// const selectRow = {
//   mode: 'checkbox',
//   clickToSelect: true,
// };

// export default class Table extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       columns: [
//         {
//           dataField: 'Sample_ID',
//           text: 'Sample ID',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '155px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'AgeFld',
//           text: 'Age',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '50px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'Gender',
//           text: 'Gender',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '85px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'ProductName',
//           text: 'Product Name',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '160px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'ValueFld',
//           text: 'Value',
//           sort: true,
//           sortFunc: (a, b, order) => (order === 'asc' ? Number(b) - Number(a) : Number(a) - Number(b)),
//           align: 'center',
//           headerStyle: () => ({
//             width: '75px',
//             textAlign: 'center',
//             overflow: 'hidden',
//             whiteSpace: 'nowrap',
//             textOverflow: 'ellipsis',
//           }),
//         },
//         {
//           dataField: 'Unit_of_Measure',
//           text: 'Units',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '65px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'SampleType',
//           text: 'Sample Type',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '90px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'Collection_Date',
//           text: 'Collection Date',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '115px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'TotalCurrentAmount',
//           text: 'Volume (mLs)',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '75px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'Thaw_Freeze',
//           text: '# of Thaw-Freeze',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '95px', textAlign: 'center' }),
//         },
//         {
//           dataField: 'Instrument',
//           text: 'Instrument',
//           sort: true,
//           align: 'center',
//           headerStyle: () => ({ width: '180px', textAlign: 'center' }),
//         },
//       ],
//     };
//   }

//   handleTableChange = async (type, { page, sizePerPage }) => {
//     try {
//       const response = await fetch(`/view-data2?page=${page}&size=${sizePerPage}`);
//       const data = await response.json();

//       this.setState({
//         products: data.samples,
//         total: data.total,
//       });
//     } catch (error) {
//       console.error('Error fetching paginated data: ', error);
//     }
//   };

//   render() {
//     const { products, showTable } = this.props;

//     // Configuración de la paginación
//     const options = {
//       paginationSize: 6,
//       pageStartIndex: 1,
//       firstPageText: 'First',
//       prePageText: 'Back',
//       nextPageText: 'Next',
//       lastPageText: 'Last',
//       showTotal: true,
//       sizePerPageList: [
//         { text: '5', value: 5 },
//         { text: '10', value: 10 },
//         { text: '30', value: 30 },
//         { text: '50', value: 50 },
//         { text: '100', value: 100 },
//         { text: 'All', value: this.state.total },
//       ],
//       remote: true,
//       onTableChange: this.handleTableChange,
//     };

//     return (
//       <div>
//         {showTable && (
//           <div>
//             {/* CSV Export Button */}
//             <CSVLink
//               data={products}
//               filename="ComplexAntibodies-ProductSelection.csv"
//               style={{
//                 color: 'white',
//                 borderStyle: 'solid',
//                 backgroundColor: 'rgb(58, 12, 207)',
//                 padding: '12px 20px',
//                 marginBottom: '15px',
//                 boxSizing: 'border-box',
//                 border: '2px solid rgb(58, 12, 207)',
//                 borderRadius: '20px',
//               }}
//             >
//               Export Selected
//             </CSVLink>
//             <br />
//             <text>
//               *Indicates some degree of hemolysis <br />
//               **Indicates some degree of icteremia
//             </text>

//             {/* Bootstrap Table */}
//             <BootstrapTable keyField="Sample_ID" data={products} columns={this.state.columns} pagination={paginationFactory(options)} selectRow={selectRow} />
//           </div>
//         )}
//       </div>
//     );
//   }
// }

/* Code for mui-datables */

import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, Modal, Slider, Button, TableFooter, TableRow, TablePagination } from '@mui/material';
import { CSVLink } from 'react-csv';

const Table = ({ products, onProductsUpdate }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = sessionStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [showCartModal, setShowCartModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedProductNames, setSelectedProductNames] = useState([]);
  const [ageRange, setAgeRange] = useState([0, 100]);
  const [valueRange, setValueRange] = useState([0, 1000]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    setTotalRecords(products.length);
  }, [products]);

  useEffect(() => {
    const updatedSelectedRows = products
      .map((product, index) => (cart.some((item) => item.Sample_ID === product.Sample_ID) ? index : null))
      .filter((index) => index !== null);
    setSelectedRows(updatedSelectedRows);
  }, [cart, products]);

  useEffect(() => {
    if (showCartModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showCartModal]);

  const handleRowSelectionChange = (_, allRowsSelected) => {
    setSelectedRows(allRowsSelected.map((row) => row.dataIndex));
    setButtonDisabled(allRowsSelected.length === 0);
  };

  const handleRemoveItem = (sampleId) => {
    setCart((prevCart) => prevCart.filter((item) => item.Sample_ID !== sampleId));
  };

  const handleRemoveSelectedItems = () => {
    setCart((prevCart) => prevCart.filter((item) => !selectedRows.some((index) => item.Sample_ID === products[index].Sample_ID)));
    setSelectedRows([]);
    setButtonDisabled(true);
  };

  const handleClearCart = () => {
    setCart([]);
  };

  const handleRemoveMultipleItems = () => {
    setCart((prevCart) => prevCart.filter((item) => !selectedProductNames.includes(item.ProductName)));
    setShowRemoveModal(false);
  };

  const handleCheckboxChange = (productName) => {
    setSelectedProductNames((prevSelected) => {
      if (prevSelected.includes(productName)) {
        return prevSelected.filter((name) => name !== productName);
      } else {
        return [...prevSelected, productName];
      }
    });
  };

  const handleAddToCart = () => {
    const updatedCart = [...cart];
    selectedRows.forEach((rowIndex) => {
      const product = products[rowIndex];
      if (!updatedCart.some((item) => item.Sample_ID === product.Sample_ID)) {
        updatedCart.push(product);
      }
    });
    setCart(updatedCart);
    setSelectedRows([]);
    setButtonDisabled(true);
  };

  const loadAllProducts = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/prod/api/v1/productname?search=${encodeURIComponent(products[0]?.ProductName || '')}`);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setAllProducts(data);
      setTotalRecords(data.length);
    } catch (error) {
      console.error('Error fetching all products: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = async (action, tableState) => {
    if (action === 'changePage' || action === 'changeRowsPerPage') {
      const newPage = tableState.page + 1;
      const newRowsPerPage = tableState.rowsPerPage;
      setIsLoading(true);
      setPage(newPage);
      setRowsPerPage(newRowsPerPage);

      if (newRowsPerPage === 'All') {
        if (allProducts.length === 0) {
          await loadAllProducts();
        }
      } else {
        try {
          setIsLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/prod/api/v1/productname?search=${encodeURIComponent(
              products[0]?.ProductName || ''
            )}&page=${newPage}&size=${newRowsPerPage}`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch products');
          }
          const data = await response.json();

          setTotalRecords(data.total);
        } catch (error) {
          console.error('Error fetching paginated data: ', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const customPaginationTotalRender = (displayData) => {
    if (rowsPerPage === 'All') {
      return `Showing all ${totalRecords} products`;
    }

    const from = page * rowsPerPage + 1;
    const to = Math.min((page + 1) * rowsPerPage, totalRecords);

    return `${from}-${to} of ${totalRecords}`;
  };

  const minAge = Math.min(...products.map((product) => product.Age));
  const maxAge = Math.max(...products.map((product) => product.Age));

  const minValue = Math.min(...products.map((product) => product.ValueFld));
  const maxValue = Math.max(...products.map((product) => product.ValueFld));

  const uniqueProductNames = [...new Set(cart.map((product) => product.ProductName))];

  const hasNotes = products.some((product) => product.Notes && product.Notes.trim() !== '');

  const filteredProducts = products.filter((product) => {
    const age = product.Age >= ageRange[0] && product.Age <= ageRange[1];
    const isNumeric = /^\d+$/.test(product.ValueFld);
    const value = isNumeric ? product.ValueFld >= valueRange[0] && product.ValueFld <= valueRange[1] : true;
    return age && value;
  });

  const selectedProducts = selectedRows.map((rowIndex) => filteredProducts[rowIndex]);

  console.log('Selected Products: ', selectedProducts);

  const formattedSelectedProducts = selectedProducts.filter(Boolean).map((product) => ({
    Sample_ID: product.Sample_ID || '',
    Age: product.Age || '',
    Gender: product.Gender || '',
    ProductName: product.ProductName || '',
    ValueFld: product.ValueFld || '',
    Unit_of_Measure: product.Unit_of_Measure || '',
    SampleType: product.SampleType || '',
    Collection_Date: product.Collection_Date || '',
    TotalCurrentAmount: product.TotalCurrentAmount || '',
    Thaw_Freeze: product.Thaw_Freeze || '',
    Instrument: product.Instrument || '',
    Notes: product.Notes || '',
  }));

  console.log(formattedSelectedProducts);

  const columnsTable = [
    {
      name: 'Sample_ID',
      label: 'Sample ID',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '400px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Age',
      label: 'Age',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', fontFamily: 'Roboto', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
        filter: true,
        customFilterListOptions: {
          render: () => `Age: ${ageRange[0]} - ${ageRange[1]}`,
        },
        filterType: 'custom',
        filterOptions: {
          logic(age) {
            return age < ageRange[0] || age > ageRange[1];
          },
          display: () => (
            <Box sx={{ width: 200, padding: 2 }}>
              <p style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'flex-start' }}>Age</p>
              <Slider
                sx={{ color: 'rgb(58, 12, 207)' }}
                value={ageRange}
                onChange={(_, newValue) => setAgeRange(newValue)}
                valueLabelDisplay="auto"
                min={minAge}
                max={maxAge}
              />
            </Box>
          ),
        },
      },
    },
    {
      name: 'Gender',
      label: 'Gender',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'ProductName',
      label: 'Product Name',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'ValueFld',
      label: 'Value',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),

        filter: true,
        customFilterListOptions: {
          render: () => `Value: ${valueRange[0]} - ${valueRange[1]}`,
        },
        filterType: 'custom',
        filterOptions: {
          logic(value) {
            return value < valueRange[0] || value > valueRange[1];
          },
          display: () => (
            <Box sx={{ width: 200, padding: 2 }}>
              <p style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'flex-start' }}>Value</p>
              <Slider
                sx={{ color: 'rgb(58, 12, 207)' }}
                value={valueRange}
                onChange={(_, newValue) => setValueRange(newValue)}
                valueLabelDisplay="auto"
                min={minValue}
                max={maxValue}
              />
            </Box>
          ),
        },
      },
    },
    {
      name: 'Unit_of_Measure',
      label: 'Units',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'SampleType',
      label: 'Sample Type',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Collection_Date',
      label: 'Collection Date',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'TotalCurrentAmount',
      label: 'Volume (mLs)',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Thaw_Freeze',
      label: '# of Thaw-Freeze',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    {
      name: 'Instrument',
      label: 'Instrument',
      options: {
        setCellProps: () => ({ style: { textAlign: 'center', width: '300px' } }),
        setRowProps: () => ({ style: { height: '35px' } }),
      },
    },
    ...(hasNotes
      ? [
          {
            name: 'Notes',
            label: 'Notes',
            options: {
              setCellProps: () => ({ style: { textAlign: 'center', width: '150px' } }),
              setRowProps: () => ({ style: { height: '35px' } }),
            },
          },
        ]
      : []),
  ];

  const optionsTable = {
    filter: true,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filterType: 'multiselect',
    responsive: 'standard',
    selectableRows: 'multiple',
    onRowSelectionChange: handleRowSelectionChange,
    rowsSelected: selectedRows,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 50, 100, 200, { label: 'All', value: 'All' }],
    tableLayout: 'auto',
    onTableChange: handleTableChange,
    progressPending: isLoading,
    serverSide: rowsPerPage === 'All',
    count: totalRecords,
    page: page,
    sort: true,
    customToolbarSelect: () => null,
    fixHeader: 'true',
    customTableBodyFooter: () => <div style={{ height: '40px', textAlign: 'center' }}></div>,
    setRowProps: (rowData, rowIndex) => ({
      sx: {
        '& .MuiTableRow-root:hover': {
          backgroundColor: 'inherit !important',
        },
        backgroundColor: `${rowIndex % 2 !== 0 ? 'rgba(57, 12, 207, 0.3)' : '#ffffff'} !important`,
        '&:hover': {
          backgroundColor: `${rowIndex % 2 !== 0 ? 'rgba(57, 12, 207, 0.3)' : '#ffffff'} !important`,
        },
      },
    }),

    setTableProps: () => ({
      sx: {
        '& .MuiTableCell-head': {
          borderRight: '2px solidrgba(255, 255, 255, 0)',
          fontWeight: 'bold',
          padding: '10px',
          textAlign: 'center',
        },

        '& .MuiTableCell-head:last-child': {
          borderRight: 'none',
        },
        '& thead': {
          color: '#3a0ccf',
          border: '4px solid #3a0ccf',
          '& .MuiCheckbox-root': {
            color: '#ffffff',
            '&:checked': {
              color: '#ffffff',
              backgroundColor: '#ffffff',
              borderColor: '#ffffff',
            },
          },
          '& th': {
            fontWeight: 'bold',
            backgroundColor: '#3a0ccf',
            color: '#ffffff',
          },
        },
      },
    }),
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalRecords}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, newPage) => changePage(newPage)}
              onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
              labelDisplayedRows={customPaginationTotalRender}
              rowsPerPageOptions={[10, 50, 100, 200, { label: 'All', value: 'All' }]}
            />
          </TableRow>
        </TableFooter>
      );
    },
  };

  const optionsTableCart = {
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    tableLayout: 'auto',
    selectedRows: 'none',
    tableBodyHeight: cart.length > 0 ? `${Math.min(400 + cart.length * 50, 600)}px` : '200px',
    draggableColumns: false,
    selectableRows: 'none',
    sort: true,
    pagination: false,
  };

  useEffect(() => {
    setPage(0);
  }, [products]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} flexWrap="wrap">
        {/* Button clear selected */}
        <Button
          sx={{
            marginLeft: '10px',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: buttonDisabled ? '#d3d3d3' : 'white',
            color: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            border: '4px solid',
            borderColor: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            borderRadius: '5px',
            cursor: buttonDisabled ? 'not-allowed' : 'pointer',
            opacity: buttonDisabled ? 0.5 : 1,
            pointerEvents: buttonDisabled ? 'none' : 'auto',
          }}
          style={{ marginLeft: '10px' }}
          onClick={handleRemoveSelectedItems}
          disabled={buttonDisabled}
        >
          Clear selected
        </Button>

        {/* Button Download CSV */}
        <CSVLink data={formattedSelectedProducts} filename="ComplexAntibodies-ProductSelection.csv" style={{ textDecoration: 'none' }}>
          <Button
            sx={{
              marginLeft: '10px',
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: buttonDisabled ? '#d3d3d3' : 'rgb(58, 12, 207)',
              color: buttonDisabled ? 'b0b0b0' : 'white',
              border: '4px solid',
              borderColor: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
              borderRadius: '5px',
              cursor: buttonDisabled ? 'not-allowed' : 'pointer',
              opacity: buttonDisabled ? 0.5 : 1,
              pointerEvents: buttonDisabled ? 'none' : 'auto',
            }}
            disabled={buttonDisabled}
          >
            Download CSV
          </Button>
        </CSVLink>

        {/* Button Add to cart */}
        <Button
          sx={{
            marginLeft: '10px',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: buttonDisabled ? '#d3d3d3' : 'white',
            color: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            border: '4px solid',
            borderColor: buttonDisabled ? 'b0b0b0' : 'rgb(58, 12, 207)',
            borderRadius: '5px',
            cursor: buttonDisabled ? 'not-allowed' : 'pointer',
            opacity: buttonDisabled ? 0.5 : 1,
            pointerEvents: buttonDisabled ? 'none' : 'auto',
          }}
          onClick={handleAddToCart}
          style={{ marginLeft: '10px' }}
          disabled={buttonDisabled}
        >
          Add to cart
        </Button>

        {/* Button Cart */}
        <Button
          sx={{
            marginLeft: '10px',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: 'white',
            color: 'rgb(58, 12, 207)',
            border: '4px solid',
            borderColor: 'rgb(58, 12, 207)',
            borderRadius: '5px',
            cursor: 'pointer',
            opacity: 1,
            alignSelf: selectedRows.length > 0 ? 'flex-start' : 'flex-end',
          }}
          onClick={() => setShowCartModal(true)}
          disabled={false}
        >
          Cart ({cart.length})
        </Button>
      </Box>

      {/* Main products table */}
      <Box sx={{ width: '100%', overflowX: 'auto', marginTop: '10px' }}>
        <MUIDataTable
          title=""
          data={rowsPerPage === 'All' ? allProducts : products}
          columns={columnsTable}
          options={{ ...optionsTable, tableLayout: 'auto', page }}
        />
      </Box>

      {/* Modal cart */}
      <Modal open={showCartModal} onClose={() => setShowCartModal(false)} sx={{ overflowY: 'auto' }}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '90%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            zIndex: 1400,
            // overflow: 'auto',
            overflowY: 'auto',
          }}
        >
          <button
            onClick={() => setShowCartModal(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '18px',
              cursor: 'pointer',
              color: '#000',
            }}
          >
            <span>&times;</span>
          </button>
          <h3>Selected Products (Cart)</h3>
          {cart.length > 0 ? (
            <div>
              {/* Table cart */}

              <Box sx={{ width: '100%', overflowX: 'auto', marginTop: '10px' }}>
                <MUIDataTable
                  data={cart.map((product) => ({
                    ...product,
                    action: (
                      <Button
                        onClick={() => handleRemoveItem(product.Sample_ID)}
                        style={{
                          backgroundColor: 'rgb(58, 12, 207)',
                          color: 'white',
                          padding: '5px',
                          cursor: 'pointer',
                        }}
                      >
                        Remove
                      </Button>
                    ),
                  }))}
                  columns={[...columnsTable, { name: 'action', label: 'Actions' }]}
                  options={{ ...optionsTableCart, tableLayout: 'auto', pagination: false, responsive: 'standard' }}
                />
              </Box>

              {/* Button clear all cart */}
              <Button
                onClick={handleClearCart}
                sx={{
                  marginLeft: '10px',
                  marginTop: '20px',
                  padding: '10px 20px',
                  backgroundColor: 'white',
                  color: 'rgb(58, 12, 207)',
                  border: '4px solid',
                  borderColor: 'rgb(58, 12, 207)',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Clear Cart
              </Button>

              {/* Button remove multiple cart */}
              <Button
                onClick={() => setShowRemoveModal(true)}
                sx={{
                  marginLeft: '10px',
                  marginTop: '20px',
                  padding: '10px 20px',
                  backgroundColor: 'white',
                  color: 'rgb(58, 12, 207)',
                  border: '4px solid',
                  borderColor: 'rgb(58, 12, 207)',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Remove multiple
              </Button>

              {/* Modal Remove multiple */}
              {showRemoveModal && (
                <div style={{ background: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#fff',
                      padding: '20px',
                      borderRadius: '10px',
                      zIndex: 1500,
                      width: '300px',
                    }}
                  >
                    <button
                      onClick={() => setShowRemoveModal(false)}
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '18px',
                        cursor: 'pointer',
                        color: '#000',
                      }}
                    >
                      <span>&times;</span>
                    </button>
                    <h3>Remove Products</h3>
                    {uniqueProductNames.map((name) => (
                      <div key={name}>
                        <label>
                          <input type="checkbox" checked={selectedProductNames.includes(name)} onChange={() => handleCheckboxChange(name)} />
                          {name}
                        </label>
                      </div>
                    ))}
                    <Button
                      onClick={handleRemoveMultipleItems}
                      sx={{
                        marginLeft: '10px',
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: 'rgb(58, 12, 207)',
                        color: 'white',
                        border: '4px solid',
                        borderColor: 'rgb(58, 12, 207)',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      Remove Selected
                    </Button>
                  </div>
                </div>
              )}

              {/* Csv */}
              <CSVLink data={cart} filename={'ComplexAntibodies-ProductSelection.csv'}>
                <Button
                  sx={{
                    marginLeft: '10px',
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: 'rgb(58, 12, 207)',
                    color: 'white',
                    border: '4px solid',
                    borderColor: 'rgb(58, 12, 207)',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgb(58, 12, 207)',
                      boxShadow: 'none',
                    },
                  }}
                  disableElevation
                  onMouseEnter={(e) => e.preventDefault()}
                  onMouseLeave={(e) => e.preventDefault()}
                >
                  Download CSV
                </Button>
              </CSVLink>
            </div>
          ) : (
            <div>No products in the cart</div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Table;
