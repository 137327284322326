import React, { Component } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { SectionHeading } from 'components/misc/Headings.js';
import { ReactComponent as SvgDecoratorBlob3 } from '../../images/svg-decorator-blob-3.svg';
import axios from 'axios';
import dateFormat from 'dateformat';
import { ContentWithPaddingXl, Container } from 'components/misc/Layouts';
import Table from './table';

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center justify-center max-w-screen-xl mx-auto pt-4`}
  min-height: auto;
  padding-bottom: 0;
`;

const Heading = tw(SectionHeading)`text-3xl font-bold text-center mb-6`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const ProductListContainer = tw.div`
  w-full mt-8
`;

const ProductColumn = tw.div`
  flex flex-wrap justify-center w-full
`;

const ProductItem = tw.div`
  w-1/3 sm:w-1/4 md:w-1/6 text-center py-4 px-4 order-1 border-2 border-gray-300 rounded-lg shadow-lg hover:shadow-xl hover:bg-gray-100 cursor-pointer
`;

const SearchForm = tw.form`
  flex flex-col justify-center items-center w-full mb-8
`;

const SearchInput = tw.input`
  p-2 w-full sm:w-1/2 md:w-1/3 border-2 border-gray-300 rounded-lg shadow-sm
`;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchbox_query: '',
      searchOptions: [],
      showTable: false,
      products: [],
      cart: [],
      // nuevo
      isLoading: false,
      allProductNames: [],
    };

    this.handleSearchBoxChange = this.handleSearchBoxChange.bind(this);
  }

  componentDidMount() {
    const savedCart = sessionStorage.getItem('cart');
    if (savedCart) {
      this.setState({ cart: JSON.parse(savedCart) });
    }
    this.fetchAllProductNames();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.cart !== this.state.cart) {
      sessionStorage.setItem('cart', JSON.stringify(this.state.cart));
    }
  }

  // Fetch all product names to populate search options
  fetchAllProductNames = () => {
    console.log('Executing fetchAllProductNames');
    const url = `${process.env.REACT_APP_API_URL}/prod/api/v1/product-names`;
    console.log(url);

    this.setState({ isLoading: true });
    axios
      .get(url)
      .then((response) => {
        const allProductNames = response.data;
        this.setState({ allProductNames, searchOptions: allProductNames, isLoading: false });
      })
      .catch((error) => {
        console.error('Error al obtener los nombres de productos:', error);
        this.setState({ searchOptions: [], isLoading: false });
      });
  };

  // Handle search box input change
  handleSearchBoxChange(e) {
    const query = e.target.value;
    this.setState({ searchbox_query: query });

    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      if (query.length > 0) {
        this.handleUpdateSearchOptions(query);
      } else {
        this.setState({ searchOptions: [] });
      }
    }, 300);
  }

  handleUpdateSearchOptions(query) {
    const { allProductNames } = this.state;
    const filteredProducts = allProductNames.filter((product) => product.toLowerCase().includes(query.toLowerCase()));
    this.setState({ searchOptions: filteredProducts });
  }

  handleSearchClick(productName) {
    this.setState({ searchbox_query: productName, searchOptions: [] });
    this.setState({ showTable: true }, () => {
      this.handleUpdateProducts(productName);
    });
  }

  handleUpdateProducts(productName) {
    const encodedProductName = encodeURIComponent(productName);
    const url = `${process.env.REACT_APP_API_URL}/prod/api/v1/productname?search=${encodedProductName}`;
    console.log('The url for update products is:', url);

    this.setState({ isLoading: true });
    axios
      .get(url)
      .then((response) => {
        console.log('Reponse data:', response.data);
        const products_with_new_date = response.data.map((product) => {
          product.Collection_Date = dateFormat(product.Collection_Date, 'mm/dd/yyyy');
          product.SamplesCreationDate = dateFormat(product.SamplesCreationDate, 'mm/dd/yyyy');
          return product;
        });
        this.setState({ products: products_with_new_date, showTable: true, isLoading: false });
      })
      .catch((error) => {
        console.error('Error to update products:', error);
        this.setState({ products: [], showTable: false, isLoading: false });
      });
  }

  handleProductsUpdate = (newProducts) => {
    const products_with_new_date = newProducts.map((product) => {
      product.Collection_Date = dateFormat(product.Collection_Date, 'mm/dd/yyyy');
      product.SamplesCreationDate = dateFormat(product.SamplesCreationDate, 'mm/dd/yyyy');
      return product;
    });
    this.setState({ products: products_with_new_date });
  };

  // Cart management
  handleAddToCart = (product) => {
    if (!this.state.cart.some((item) => item.Sample_ID === product.Sample_ID)) {
      this.setState((prevState) => ({ cart: [...prevState.cart, product] }));
    }
  };

  handleRemoveFromCart = (sampleId) => {
    this.setState((prevState) => ({
      cart: prevState.cart.filter((item) => item.Sample_ID !== sampleId),
    }));
  };

  handleClearCart = () => {
    this.setState({ cart: [] });
  };

  render() {
    console.log('Products:', this.state.products);
    return (
      <div id="inventory">
        <Container>
          <ContentWithPaddingXl>
            <ThreeColumnContainer>
              <Heading>
                Our <span tw="text-primary-500">Inventory</span>
              </Heading>
              {/* <SearchForm>
                <label>
                  <SearchInput
                    placeholder="Search our Inventory"
                    name="searchboxtext"
                    type="text"
                    value={this.state.searchbox_query}
                    onClick={this.fetchAllProductNames}
                    onChange={this.handleSearchBoxChange}
                  />
                </label>
                {this.state.isLoading ? (
                  <p>Loading data...</p>
                ) : this.state.searchOptions.length > 0 ? (
                  <ProductListContainer>
                    <ProductColumn>
                      {this.state.searchOptions.map((product, index) => (
                        <ProductItem key={index} onClick={() => this.handleSearchClick(product)}>
                          {product}
                        </ProductItem>
                      ))}
                    </ProductColumn>
                  </ProductListContainer>
                ) : null}
              </SearchForm> */}
              {/* {this.state.showTable && <Table products={this.state.products} addToCart={this.handleAddToCart} onProductsUpdate={this.handleProductsUpdate} />} */}
            <h1> The search functionality is currently being worked on, please reach out to sales@complexantibodies.com for the latest inventory.</h1>
            </ThreeColumnContainer>
            <DecoratorBlob />
          </ContentWithPaddingXl>
        </Container>
      </div>
    );
  }
}
